.h1-style{
    font-family: "Lucida Console", "Courier New", monospace;
    text-align: center;
    font-size: 70px;
    box-shadow: 1cap;
    color: #FAF1CF;
    text-shadow: 2px 2px black;
}

.p {
    font-family: "Lucida Console", "Courier New", monospace;
    color: black;
    box-shadow: 0cap;
    font-size: 20px;
    text-align: center;
}
.h2-style{
    font-family: "Lucida Console", "Courier New", monospace;
    text-align: center;
    color: #FAF1CF;;
    box-shadow: 1cap;
    text-shadow: 2px 2px black;
}

.img-hol {
    width: 450px;
    height: 400px;
    border-radius: 10px;
    border: 2px solid black;
    margin-left: 30px;
}

.icon {
    text-align: center;
    color: black;
    font-family: "Lucida Console", "Courier New", monospace;
}

.icon2 {
    text-align: center;
    color: black;
    font-family: "Lucida Console", "Courier New", monospace;
    margin-right: 30px;
}

.git{
    margin-left: 305px;
    color: black;
    font-family: "Lucida Console", "Courier New", monospace; 
}
.flex-container-hol {
    display: flex;
    margin-left: 200px;
   
}

.git-source-code {
    text-align: center;
    color: black;
    font-family: "Lucida Console", "Courier New", monospace;  
}

.anchor {
    color: black;
    font-family: "Lucida Console", "Courier New", monospace; 
    
}

.frame {
    display: flex;
    align-items: center;
    justify-content: center;
 }