.h1-style{
  font-family: "Lucida Console", "Courier New", monospace;
  text-align:left;
  font-size: 70px;
  box-shadow: 0cap;
  color: #FAF1CF;
  text-shadow: 2px 2px black;
}
form {
    margin-top: 75px;
}
input {
    width: 50%;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
label {
    margin-left: 500px;
    font-family: "Lucida Console", "Courier New", monospace; 
}

textarea {
    position: relative;
    left: 500px;
    width: 50%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}
input[type=submit] {
    background-color: #DDD3D1;
    margin-left: 500px;
    width: 20%;
    padding: 14px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Lucida Console", "Courier New", monospace; 

  }

  input[type=text] {
    margin-left: 500px;
  }
  input[type=email] {
    margin-left: 500px;;
  }
  
  display {
    margin-left: -700px;
  }