.h1-style{
    font-family: "Lucida Console", "Courier New", monospace;
    text-align: center;
    font-size: 70px;
    box-shadow: 0cap;
    color: black;
}
.carousel {
    height: 300px;
    width: 600px;
    align-items: center;
    margin-right: 50px;
}

.carousel-size {
    top: 100px;
    margin-left: 650px;
    width: 600px;
    height: 400px;
}

h2{
    font-family: "Lucida Console", "Courier New", monospace;
    text-align: center;
}

.img1 {
    font-family: "Lucida Console", "Courier New", monospace; 
    margin: 100px;
    
}

.flex-container {
   float: right;
   margin-right: 100px;
}

