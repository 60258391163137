#main {
    height: 1600px;
}
.img {
  border: none;
  border-radius: 50%;
}

#frame {
  position: relative;
}

#main_image {
  width: 100%;
  height: 40%;
}

#head {
  margin-right: 500px;
}

#h1m {
margin-left: 625px;
}

#computer {
    float: right;
    margin-right: 250px;
    bottom: 350px;
    position: relative;
    width: 200px;
    height: 500px;
   
}

#h2 {
  margin-left: 100px;
  width: 800px;
  height: 500px;
}

.p2 {
    font-size: 30px;
}

.p3 {
    font-size: 50px;
}



